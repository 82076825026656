<template>
  <component :is="flashCardData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="flashCardData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching flash card data
      </h4>
      <div class="alert-body">
        No flash cards found with this flash card id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'dashboard-flash-cards-list'}"
        >
          Flash Cards List
        </b-link>
        for other flash cards.
      </div>
    </b-alert>
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col md="6">
          <!-- En Front -->
          <validation-provider
              #default="validationContext"
              name="English Front"
              rules="required"
          >
            <b-form-group
                label="English Front"
                label-for="front-en"
            >
              <b-form-input
                  id="front-en"
                  v-model="flashCardData.front_en"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Ar Front -->
          <b-form-group
              label="Arabic Front"
              label-for="front-ar"
          >
            <b-form-input
                id="front-ar"
                v-model="flashCardData.front_ar"
                autofocus
                trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
          <b-col md="6">
            <!-- English Back -->
            <validation-provider
                #default="validationContext"
                name="English Back"
                rules="required"
            >
              <b-form-group
                  label="English Back"
                  label-for="back-en"
              >
                <b-form-input
                    id="back-en"
                    v-model="flashCardData.back_en"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <!-- Arabic Back -->
            <b-form-group
                label="Arabic Back"
                label-for="back-ar"
            >
              <b-form-input
                  id="back-ar"
                  v-model="flashCardData.back_ar"
                  autofocus
                  trim
              />
            </b-form-group>
          </b-col>
        </b-row>
      <b-row>

        <b-col md="6">
          <!-- Course -->
          <validation-provider
              #default="validationContext"
              name="Choose Course"
              rules="required"
          >
            <b-form-group
                label="Choose Course"
                label-for="course"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="flashCardData.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="courseOptions"
                  @input="val => updateCourseID(val)"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Category -->
          <validation-provider
              v-if="categoriesOptions.length > 0"
              #default="validationContext"
              name="Choose Category"
              rules="required"
          >
            <b-form-group
                label="Choose Category"
                label-for="category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="flashCardData.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriesOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  @input="val => updateCategoryId(val)"
                  input-id="category-id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Category -->
          <validation-provider
              v-if="flashCardData.subs.length > 0"
              #default="validationContext"
              name="Choose Sub Category"
              rules="required"
          >
            <b-form-group
                label="Choose Sub Category"
                label-for="sub-category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="flashCardData.sub_category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="flashCardData.subs"
                  :reduce="val => val.value"
                  :clearable="false"
                  @input="val => updateSubCategoryId(val)"
                  input-id="category-id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is Free Content"
              label-for="is_free_content"
          >
            <b-form-checkbox
                id="is_free_content"
                v-model="flashCardData.is_free_content"
                :checked=flashCardData.is_free_content
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Is Active"
              label-for="is_active"
          >
            <b-form-checkbox
                id="is_active"
                v-model="flashCardData.is_active"
                :checked=flashCardData.is_active
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
      </b-row>


      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import flashCardStoreModule from '../flashcardStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BAlert, BButton, BCard, BCol, BForm, BFormInvalidFeedback,BFormCheckbox, BFormGroup, BFormFile, BFormInput, BLink, BRow,BFormTextarea} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { required } from '@validations'
import vSelect from 'vue-select'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BCard,
    BFormInvalidFeedback,
    BFormFile,
    BFormTextarea,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,

    //Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  created() {
    this.$http.get('/all-courses')
        .then(response => {
          let that = this
          this.lists.splice(0)
          this.lists = response.data.data.map(function(item){
            that.courseOptions.push({
              label : item.title_en,
              value: item.id+""
            })
          })
          let course_id = this.flashCardData.course_id
          this.$http.get('/categories?course_id='+course_id)
              .then(response => {
                let that = this
                this.lists = response.data.data.map(function(item){
                  that.categoriesOptions.push({
                    label : item.label,
                    value: item.value+""
                  })
                })
              })
        })
    console.log(this.flashCardData.sub_category);
  },
  methods:{
    updateSubCategoryId(val){
      this.flashCardData.sub_category_id = val
    },
    updateCategoryId(category_id) {
      this.flashCardData.category_id = category_id
      this.flashCardData.subs.splice(0)
      this.$http.get('/sub-categories?'+'course_id='+this.flashCardData.course_id+'&category_id='+category_id)
          .then(response => {
            let that = this
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.flashCardData.subs.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
    },
    updateCourseID(course_id){
      this.flashCardData.course_id = course_id
      this.$http.get('/categories?course_id='+course_id)
          .then(response => {
            let that = this
            this.categoriesOptions.splice(0)
            this.flashCardData.subs.splice(0)
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoriesOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
      this.updateCategoryId(this.flashCardData.category_id)
    },
  },
  data(){
    return{
      required,
      courseOptions: [],
      lists: [],
      categoriesOptions: [],
      subCategoriesOptions: [],
    }
  },
  setup() {
    const blankFlashCardData = {
      back_en: '',
      back_ar: null,
      front_en: '',
      front_ar: null,
      course_id: '',
      category_id: '',
      sub_category_id: '',
      is_free_content: null,
      subs: [],
    }
    const lectureImageId = ref(null);
    const toast = useToast()

    const flashCardData = ref(JSON.parse(JSON.stringify(blankFlashCardData)))
    const resetFlashCardData = () => {
      flashCardData.value = JSON.parse(JSON.stringify(blankFlashCardData))
    }

    const FLASH_CARD_STORE_MODULE_NAME = 'flash-cards'

    // Register module
    if (!store.hasModule(FLASH_CARD_STORE_MODULE_NAME)) store.registerModule(FLASH_CARD_STORE_MODULE_NAME, flashCardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FLASH_CARD_STORE_MODULE_NAME)) store.unregisterModule(FLASH_CARD_STORE_MODULE_NAME)
    })
    store.dispatch('flash-cards/fetchFlashCard', {id: router.currentRoute.params.id})
        .then(response => {
          flashCardData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            flashCardData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        back_en: flashCardData.value.back_en,
        back_ar: flashCardData.value.back_ar,
        front_en: flashCardData.value.front_en,
        front_ar: flashCardData.value.front_ar,
        course_id: flashCardData.value.course_id,
        category_id: flashCardData.value.category_id,
        sub_category_id: flashCardData.value.sub_category_id,
        is_free_content: flashCardData.value.is_free_content,
      }
      store.dispatch('flash-cards/updateFlashCard', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'dashboard-flash-cards-list'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Flash Card Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFlashCardData)
    return {
      onUpdate,
      flashCardData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
